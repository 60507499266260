export interface SiteSettings {
  "ccweb.cardholderSearch.columns": string | null | undefined
}

export interface PatchSiteSettings {
  "ccweb.cardholderSearch.columns"?: PatchSetting<"string">
}

export interface OperatorSettings {
  "ccweb.cardholderSearch.lastSimpleSearchFilter"?: string | null | undefined
  "ccweb.general.theme"?: string | null | undefined
}

export interface PatchOperatorSettings {
  "ccweb.cardholderSearch.lastSimpleSearchFilter"?: PatchSetting<"string">
  "ccweb.general.theme"?: PatchSetting<"string">
}

type SettingsValueType = "string" | "boolean" | "int" | "double" | "datetime" | "byte[]" | "guid"

export type PatchSetting<T extends SettingsValueType> = {
  type: T
  value: T extends "string"
    ? string | null
    : T extends "boolean"
    ? boolean | null
    : T extends "int"
    ? number | null
    : T extends "double"
    ? number | null
    : T extends "datetime"
    ? string | null
    : T extends "byte[]"
    ? string | null // base64 encoded string
    : T extends "guid"
    ? string | null
    : never
}

export type SiteSettingsName = keyof SiteSettings
export type OperatorSettingsName = keyof OperatorSettings

export const convertSiteSettingsToPatch = (settings: SiteSettings): PatchSiteSettings => {
  const patch: PatchSiteSettings = {}
  for (const [key, value] of Object.entries(settings)) {
    if (value === undefined) continue

    switch (key) {
      case "ccweb.cardholderSearch.columns":
        patch[key] = {
          type: "string",
          value: value
        }
        break
      default:
        break
    }
  }
  return patch
}

export const convertOperatorSettingsToPatch = (settings: OperatorSettings): PatchOperatorSettings => {
  const patch: PatchOperatorSettings = {}
  for (const [key, value] of Object.entries(settings)) {
    if (value === undefined) continue

    switch (key) {
      case "ccweb.cardholderSearch.lastSimpleSearchFilter":
      case "ccweb.general.theme":
        patch[key] = {
          type: "string",
          value: value
        }
        break
      default:
        break
    }
  }
  return patch
}

//
// Cardholder Search Columns settings
//
export const CARDHOLDER_SEARCH_COLUMN_FIELDS = ["firstName", "lastName", "description", "cardNumbers", "division", "lastZone", "authorised"] as const
export type CardholderSearchColumnField = (typeof CARDHOLDER_SEARCH_COLUMN_FIELDS)[number]
export type CardholderSearchFieldColumn = { name: CardholderSearchColumnField }
export type CardholderSearchPdfColumn = { pdfId: number }
export type CardholderSearchNonSelectedColumn = {}
export type CardholderSearchColumn = CardholderSearchFieldColumn | CardholderSearchPdfColumn | CardholderSearchNonSelectedColumn
